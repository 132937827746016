import _ from 'lodash'
import React from 'react'
import { DEFAULT_ITEM_HEIGHT, makeItemStyle, PickerWheelItem } from './picker-wheel-item'

import 'browser/mobile/components/picker-wheel/_picker-wheel.scss'

const DRAG_DISTANCE_THRESHOLD = 10

/** The direction of the curvature, used as a factor. */
enum WheelCurvature {
  Left = -1,
  Flat = 0,
  Right = 1,
}

interface IPickerWheelProps {
  items: any[]
  curvature: WheelCurvature
  selectedItem?: any
  onItemSelected?: (item: any, idx: number) => void
  initialIndex?: number
}

interface IPickerWheelState {
  renderedOffset: number
  /**
   * Ensures 'none' transition is used on initial render (set renderedOffset ->
   * render [none] -> set isInitialized -> render [easing enabled for
   * scrolling]).
   */
  isInitialized: boolean
}

export class PickerWheel extends React.Component<IPickerWheelProps, IPickerWheelState> {
  private wheelRef = React.createRef<HTMLDivElement>()
  private isPointerDown = false
  private pointerDownTarget: EventTarget = null
  private initialPointerY = 0
  private initialPointerOffset = 0
  private dragThresholdSatisfied = false
  /* offset value to facilitate mouse handling */
  private offsetFromTop = 0

  constructor(props: IPickerWheelProps) {
    super(props)
    this.state = {
      renderedOffset: null,
      isInitialized: false,
    }
  }

  public componentDidMount(): void {
    const { items, selectedItem, initialIndex } = this.props

    const index = initialIndex ?? items.indexOf(selectedItem)
    const clampedOffset = this.getOffsetForIndex(index)
    this.setState({ renderedOffset: clampedOffset })

    this.offsetFromTop = clampedOffset
    this.initialPointerOffset = clampedOffset

    // using `capture: true` to prevent the click event from being consumed by the popover
    document.addEventListener('click', this.onClick, { capture: true })
    document.addEventListener('pointermove', this.onPointerMove)
    // using `passive: false` to enable calling preventDefault
    document.addEventListener('touchmove', this.onTouchMove, { passive: false })
  }

  public componentWillUnmount(): void {
    document.removeEventListener('click', this.onClick, { capture: true })
    document.removeEventListener('pointermove', this.onPointerMove)
    document.removeEventListener('touchmove', this.onTouchMove)
  }

  public componentDidUpdate(prevProps: IPickerWheelProps, prevState: IPickerWheelState): void {
    const { renderedOffset } = this.state
    const { renderedOffset: prevRenderedOffset } = prevState

    if (renderedOffset !== prevRenderedOffset && renderedOffset != null) {
      this.setState({ isInitialized: true })
    }
  }

  public render() {
    const { items, selectedItem, curvature } = this.props
    const { renderedOffset, isInitialized } = this.state

    return (
      <div
        ref={this.wheelRef}
        className="c-mobile-pickerWheel"
        onPointerDown={this.onPointerDown}
        onTouchEnd={this.onTouchEnd}
        style={{ overflow: 'hidden', position: 'relative' }}
      >
        <div
          style={{
            transform: `translateY(${renderedOffset ?? 0}px)`,
            transition: this.isPointerDown || !isInitialized ? 'none' : 'transform 0.3s ease',
          }}
        >
          {items.map((item, idx) => {
            if (this.wheelRef.current === null) {
              return null
            }

            // get a decimal value representing the distance from the center index
            const itemOffset = this.getOffsetForIndex(idx)
            const distanceFromCenter = Math.abs((renderedOffset ?? 0) - itemOffset)

            const wheelHeight = this.wheelRef.current.clientHeight
            const itemStyle = makeItemStyle(distanceFromCenter, wheelHeight, curvature)

            const displayValue = _.padStart(item, 2, '0')

            return (
              <PickerWheelItem
                key={idx}
                isSelected={selectedItem === item}
                onClick={() => this.handleItemClick(item, idx)}
                style={itemStyle}
              >
                {displayValue}
              </PickerWheelItem>
            )
          })}
        </div>
      </div>
    )
  }

  private onPointerDown = (e: React.PointerEvent) => {
    this.isPointerDown = true
    this.pointerDownTarget = e.currentTarget
    this.dragThresholdSatisfied = false
    this.initialPointerY = e.clientY
    this.initialPointerOffset = this.offsetFromTop

    e.preventDefault()
  }

  private onTouchMove = (e: TouchEvent) => {
    // prevent default touch scroll behavior from kicking in while we're
    // dragging the picker wheel. (doing this in onPointerMove doesn't work)
    e.preventDefault()
  }

  private onPointerMove = (e: PointerEvent) => {
    if (!this.isPointerDown) {
      return
    }

    const { items } = this.props

    const deltaY = e.clientY - this.initialPointerY
    const newOffset = this.initialPointerOffset + deltaY
    this.offsetFromTop = newOffset

    const clampedOffset = this.clampOffset(this.offsetFromTop, items.length)
    this.setState({ renderedOffset: clampedOffset })

    // set movement flag to detect drags later
    if (Math.abs(deltaY) > DRAG_DISTANCE_THRESHOLD) {
      this.dragThresholdSatisfied = true
    }
  }

  private releasePointer = () => {
    const { items, onItemSelected } = this.props

    this.isPointerDown = false

    const newIndex = this.calculateCurrentIndex(this.offsetFromTop, items.length)
    const newOffset = this.getOffsetForIndex(newIndex)
    this.offsetFromTop = newOffset
    this.setState({ renderedOffset: newOffset })

    onItemSelected(items[newIndex], newIndex)
  }

  private onClick = (e: MouseEvent) => {
    this.handleClick(e)
  }

  private onTouchEnd = (e: React.TouchEvent) => {
    this.handleClick(e)
  }

  // generic handling of either a desktop 'click' or releasing the touch on
  // phone/tablet.
  private handleClick = (e: MouseEvent | React.TouchEvent) => {
    if (this.wheelRef.current == this.pointerDownTarget) {
      // ignore external click events

      if (this.dragThresholdSatisfied) {
        // consume the event if we were previously dragging, so that the
        // popover's document click handler doesn't close the popoover if we
        // release the mouse outside of its bounds.
        e.stopPropagation()
      }

      this.releasePointer()
    }

    // reset the mouse down target
    this.pointerDownTarget = null
  }

  private handleItemClick = (item: any, index: number) => {
    const { onItemSelected } = this.props

    if (this.dragThresholdSatisfied) {
      // don't handle a 'click' event on the item if it's only acting as a drag
      // handle - let the motion of the wheel determine item selection.
      return
    }

    if (!this.wheelRef.current) {
      // might have dismounted - bail out (can happen when resuming the debugger)
      return
    }

    onItemSelected(item, index)
    const newOffset = this.getOffsetForIndex(index)
    this.offsetFromTop = newOffset
    this.setState({ renderedOffset: newOffset })
  }

  /**
   * Maps the index to its corresponding offset value.
   */
  private getOffsetForIndex(index: number) {
    const viewCenterY = this.wheelRef.current.clientHeight / 2
    return viewCenterY - index * DEFAULT_ITEM_HEIGHT - DEFAULT_ITEM_HEIGHT / 2
  }

  /**
   * Clamps the offset so the lowest item can't go below the midpoint, and the
   * highest item can't go above the midpoint.
   */
  private clampOffset(offset: number, numItems: number) {
    const viewCenterY = this.wheelRef.current.clientHeight / 2
    const maxOffset = viewCenterY - DEFAULT_ITEM_HEIGHT / 2
    const minOffset = maxOffset - (numItems - 1) * DEFAULT_ITEM_HEIGHT
    return _.clamp(offset, minOffset, maxOffset)
  }

  /**
   * Determines the current selected index based on the current scroll offset.
   */
  private calculateCurrentIndex(offset: number, numItems: number) {
    const viewCenterY = this.wheelRef.current.clientHeight / 2
    const midwayWithOffset = viewCenterY - offset - DEFAULT_ITEM_HEIGHT / 2
    const index = Math.round(midwayWithOffset / DEFAULT_ITEM_HEIGHT)
    return _.clamp(index, 0, numItems - 1)
  }
}
